import { fun_PostAction } from "@/api";
export default {
  data: () => {
    return {
      tradeCenterNoName: null, // 项目名称
      data: [],
      todayNum: 0, // 今日开标项目
      numIng: 0 // 正在开标项目
    };
  },
  mounted() {
    this.fun_GetList();
  },
  methods: {
    async fun_GetList() {
      let res = await fun_PostAction("/projectInfo/bidOpeningTodayList");
      const { result, data, msg } = res.data;
      if (result) {
        this.data = data;
        this.todayNum = data.length;
        this.tradeCenterNoName = this.data[0].tradeCenterNoName;
        this.data.map(v => {
          if (v.state === "正在进行") this.numIng++;
        });
      } else {
        this.$message.error(msg);
      }
    },
    fun_GetInto(e) {
      this.$router.push({ path: "/openHallDetails", query: { id: e.id } });
    }
  }
};
